import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import RouteController from "./routes/RouteController";
import { Analytics } from "@vercel/analytics/react";
import React from "react";

function App() {
  return (
    <div className="App relative">
      <Header />
      <RouteController />
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;
