import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();

  const handleChangeLocation = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="fixed left-0 top-0 z-30 w-full bg-[#030712] py-5">
      <div className="container flex items-center justify-between md:justify-center">
        <div className="">
          <NavLink
            to="/"
            className="text-center text-2xl font-bold leading-9 text-white md:hidden"
          >
            atom
          </NavLink>
        </div>
        <div className="flex w-full items-center justify-end gap-6 md:justify-center">
          <ul className="headerList hidden items-center gap-6 md:flex">
            <li>
              <NavLink
                to="/"
                className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              >
                {t("header.home")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              >
                {t("header.about me")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/skills"
                className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              >
                {t("header.skills")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/experience"
                className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              >
                {t("header.experience")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/work"
                className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              >
                {t("header.work")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              >
                {t("header.contact")}
              </NavLink>
            </li>
          </ul>
          <a
            href="https://drive.google.com/file/d/100hapPXK9KTCq1s6gtYRti_HEtW1qe6v/view?usp=sharing"
            className="hidden items-center justify-center gap-2 rounded-xl bg-gray-900 px-4 py-1.5 text-base font-medium leading-normal text-gray-50 transition hover:scale-105"
          >
            Resume (CV)
          </a>
          <select
            className="flex cursor-pointer appearance-none items-center justify-center gap-2 rounded-xl bg-gray-900 px-4 py-1.5 text-base font-medium leading-normal text-gray-50 outline-none transition hover:scale-105"
            name="lang"
            id="lang"
            onChange={handleChangeLocation}
            defaultValue={localStorage.getItem("lang")}
          >
            <option value="en">EN</option>
            <option value="uz">UZ</option>
            <option value="ru">RU</option>
          </select>
          <button
            className="z-30 text-white focus:outline-none md:hidden"
            onClick={toggleMenu}
          >
            {isMenuOpen ? (
              <AiOutlineClose size={24} />
            ) : (
              <AiOutlineMenu size={24} />
            )}
          </button>
        </div>
      </div>

      <div
        className={`fixed inset-y-0 right-0 z-20 flex w-64 transform flex-col justify-between bg-[#030712] p-5 pt-20 transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden`}
      >
        <ul className="flex flex-col items-start gap-6">
          <li>
            <NavLink
              to="/"
              className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              onClick={toggleMenu}
            >
              {t("header.home")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              onClick={toggleMenu}
            >
              {t("header.about me")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/skills"
              className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              onClick={toggleMenu}
            >
              {t("header.skills")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/experience"
              className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              onClick={toggleMenu}
            >
              {t("header.experience")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/work"
              className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              onClick={toggleMenu}
            >
              {t("header.work")}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              className="text-base font-medium capitalize leading-normal text-white hover:border-b-2"
              onClick={toggleMenu}
            >
              {t("header.contact")}
            </NavLink>
          </li>
        </ul>
        <div className="flex flex-col items-center justify-center text-xs opacity-75">
          <span>© 2024 Elbek Atom Giyozov</span>
          <span>All Rights Reserved</span>
        </div>
      </div>
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-10 bg-black bg-opacity-50 backdrop-blur-sm md:hidden"
          onClick={toggleMenu}
        ></div>
      )}
    </header>
  );
};

export default Header;
